import { Capacitor, CapacitorHttp } from "@capacitor/core";
import axios from "axios";

const isNative = Capacitor.isNativePlatform();

export const apiRequest = async (config = {}) => {
  const { method = "GET", url, headers = {}, data = {} } = config;

  try {
    if (isNative) {
      // Use Capacitor Http plugin in a native environment
      const response = await CapacitorHttp.request({
        method,
        url,
        headers,
        data,
      });
      return response?.data;
    } else {
      // Use Axios in a web environment
      const response = await axios({
        method,
        url,
        headers,
        data,
      });
      return response?.data;
    }
  } catch (error) {
    console.error("API Request Error:", error);
    throw error; // Re-throw error for further handling if needed
  }
};
