import React, { useState } from "react";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import { apiRequest } from "../API/apiRequest";
import OAuth from "./OAuth";

const CheckEmailPage = () => {
  const [data, setData] = useState({
    email: "",
  });
  const navigate = useNavigate();

  const handleOnChange = (e) => {
    const { name, value } = e.target;

    setData((preve) => {
      return {
        ...preve,
        [name]: value,
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    const URL = `${process.env.REACT_APP_BACKEND_URL}/api/email`;

    try {
      const response = await apiRequest({
        method: "post",
        url: URL,
        data: data,
      });

      if (response.success) {
        setData({
          email: "",
        });

        if (response?.data?.isVerified) {
          toast.success(response.msg);
          navigate("/password", {
            state: response?.data,
          });
        } else {
          toast.error(response?.msg);
        }
      }
    } catch (error) {
      toast.error(error?.response?.msg);
    }
  };

  return (
    <div className="w-full">
      <h4 className="text-center text-bold text-2xl">Login</h4>
      <form onSubmit={handleSubmit}>
        <div className="flex flex-col gap-1">
          <label htmlFor="name">Email:</label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder="Enter your email"
            className="bg-slate-200 border border-black p-2 focus:outline-[#F07023]"
            value={data.email}
            onChange={handleOnChange}
            required
          />
        </div>

        <button className="bg-[#F07023] text-lg w-full  p-2  rounded mt-2 text-white hover:text-black hover:bg-[#f4955a]">
          Next
        </button>
      </form>

      <p className="my-3 text-center">
        New User ?{" "}
        <Link to={"/register"} className="hover:text-[#F07023] font-semibold">
          Register
        </Link>
      </p>
      <p className="text-center my-2">(or)</p>
      <OAuth />
    </div>
  );
};

export default CheckEmailPage;
